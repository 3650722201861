////////////////////////////////////////////////////
// Page Animation In
////////////////////////////////////////////////////

import { gsap } from "gsap";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

////////////////////////////////////////////////////

// Function to add and remove the page transition screen
const pageInAnimation = (container) => {

	// Call the splittingjs to transform the data-splitting texts to spans of chars
	Splitting();

	const hero2L4G = document.querySelector("[data-hero='hero2L4G']");
	const title = document.querySelector(".fx-hero__title");
	const titleSub = document.querySelector(".fx-hero__title-sub");
	const gslice1 = document.querySelector(".fx-hero__gslice1");
	const gslice2 = document.querySelector(".fx-hero__gslice2");
	const gslice3 = document.querySelector(".fx-hero__gslice3");
	// const linksInfo = document.querySelector(".menu-hero-info");
	const linksMobile = document.querySelector(".fx-menu-hero-links");
	const link4Links = document.querySelectorAll(".menu-hero--item");
	const img = document.querySelector(".fx-hero__img");

	if (hero2L4G) {
		// Dom section elements
		let DOM = {
			content: {
				link4Links: {
					section: document.querySelector(".menu-hero--item"),
					get links() {
						return this.section.querySelectorAll(".menu-hero .menu-hero--item");
					},
				},
				title: {
					section: document.querySelector(".fx-hero__title"),
					get letters() {
						return this.section.querySelectorAll(
							".fx-hero__title .word > .char, .whitespace",
						);
					},
				},
				titleSub: {
					section: document.querySelector(".fx-hero__title-sub"),
					get words() {
						return this.section.querySelectorAll(".fx-hero__title-sub .word");
					},
				},
				timelineSettings: {
					staggerValue: 0.02,
					charsDuration: 0.72,
				},
				timing: 0.72,
			},
		};

		// GSAP Defaults
		const tl = gsap.timeline({
			// repeat:-1, yoyo:1,
			defaults: {
				delay: 0,
				duration: DOM.content.timing,
				ease: "power3.inOut",
				autoAlpha: 0,
			},
		});


		tl.fromTo(img, { autoAlpha: 0 },{ autoAlpha: 1, duration: DOM.content.timing })
			.fromTo(gslice1, { x: -270, autoAlpha: 0}, { x: 0, autoAlpha: 1 }, "-=90%")
			.fromTo(gslice2, { x: -110, autoAlpha: 0 }, { x: 0, autoAlpha: 1 }, "-=70%")
			.fromTo(gslice3, { x: -110, autoAlpha: 0 }, { x: 0, autoAlpha: 1 }, "-=60%")
			.to(title, { autoAlpha: 1 }, "-=100%")
			.fromTo(titleSub, { autoAlpha: 0, y: "20%", },{ autoAlpha: 1, y: "0%" }, "-=10%")
			.from(
				DOM.content.title.letters,
				{
					duration: DOM.content.timelineSettings.charsDuration,
					opacity: 0,
					y: "100%",
					stagger: {
						each: DOM.content.timelineSettings.staggerValue,
					},
				},
				"-=90%",
			)
			.fromTo(
				link4Links,
				{ x: -10 },
				{
					duration: 0.8,
					x: 0,
					autoAlpha: 1,
					stagger: {
						ease: "circ.in",
						each: 0.08,
					},
				},
				"-=110%",
			)
			.fromTo(linksMobile, { x: -20, autoAlpha: 0 }, { x: 0, autoAlpha: 1 }, "-=70%")
			// .fromTo(linksInfo, { x: -10 }, { x: 0, autoAlpha: 1 }, "-=95%");
	}
};
/////////////////////////////////////////r///////////
export { pageInAnimation };
