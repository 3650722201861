////////////////////////////////////////////////////
// FadeIn
////////////////////////////////////////////////////

// import { set } from "date-fns";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

////////////////////////////////////////////////////


const fx_pinSlider = (container) => {

	let sliderItems = null;
    let TL = gsap.timeline();

  
    sliderItems = gsap.utils.toArray(".slider__item");
        
	TL = gsap.timeline({
            defaults: { duration: 1, ease: "none" },
            scrollTrigger: {
                trigger: ".slider",
                scrub: true,
                pin: true,
                start: "top top",
                end: `+=${sliderItems.length - 1}00%`,
                snap: {
                    snapTo: "labels",
                    duration: { min: 0.3, max: 2.5 },
                    delay: 0,
                    ease: "expo.out"
                }
            }
        });
    
        TL.addLabel(`start`);
    
        sliderItems.forEach((element, index) => {
            if (index < sliderItems.length - 1) {
                TL.to(
                    ".slider__list",
                    { xPercent: -100 * (index + 1) },
                    `l${index}`
                );
                TL.addLabel(`l${index}`);
            }
        });
  
    
};

////////////////////////////////////////////////////
export { fx_pinSlider  }
