// Smooth scrolling initialization (using Lenis https://github.com/studio-freight/lenis)
import Lenis from '@studio-freight/lenis'

const smooth = (container) => {
  
  const lenis = new Lenis({
    lerp: 0.1,
    smooth: true,
  });
  const scrollFn = () => {
    lenis.raf();
    requestAnimationFrame(scrollFn);
  };
  requestAnimationFrame(scrollFn);

};


export default smooth;