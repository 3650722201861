import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

////////////////////////////////////////////////////

const fx_fadeIn = (container) => {
	const fadeIn = document.querySelector(".fx-fadeIn");

	if (fadeIn) {
		const spacing = 10;
		const staggerEach = 0.21;
		const classfade = ".fx-fadeIn";

		gsap.defaults({ ease: "power3.InOut" });
		gsap.set(classfade, { y: spacing, opacity: 0 });

		ScrollTrigger.batch(classfade, {
			onEnter: (batch) =>
				gsap.to(batch, { opacity: 1, y: 0, stagger: { each: staggerEach } }),
			// onLeave: (batch) =>
			// 	gsap.set(batch, {
			// 		opacity: 0,
			// 		y: spacing,
			// 		stagger: { each: staggerEach },
			// 	}),
			// onEnterBack: (batch) =>
			// 	gsap.to(batch, { opacity: 1, y: 0, stagger: { each: staggerEach } }),
			// onLeaveBack: (batch) =>
			// 	gsap.set(batch, {
			// 		opacity: 0,
			// 		y: spacing,
			// 		stagger: { each: staggerEach },
			// 	}),
		});
		// ScrollTrigger.addEventListener("refreshInit", () =>
		// 	gsap.set(classfade, { y: spacing, opacity: 0 }),
		// );
	}

	const fadeInSm = document.querySelector(".fx-fadeIn-sm");

	if (fadeInSm) {
		const spacing = 18;
		const staggerEach = 0.21;
		const classfade = ".fx-fadeIn-sm";

		gsap.defaults({ ease: "power3.InOut" });
		gsap.set(classfade, { y: spacing, opacity: 0 });

		ScrollTrigger.batch(classfade, {
			onEnter: (batch) =>
				gsap.to(batch, { opacity: 1, y: 0, stagger: { each: staggerEach } }),
			// onLeave: (batch) =>
			// 	gsap.set(batch, {
			// 		opacity: 0,
			// 		y: spacing,
			// 		stagger: { each: staggerEach },
			// 	}),
			onEnterBack: (batch) =>
				gsap.to(batch, { opacity: 1, y: 0, stagger: { each: staggerEach } }),
			onLeaveBack: (batch) =>
				gsap.set(batch, {
					opacity: 0,
					y: spacing,
					stagger: { each: staggerEach },
				}),
		});
		// ScrollTrigger.addEventListener("refreshInit", () =>
		// 	gsap.set(classfade, { y: spacing, opacity: 0 }),
		// );
	}

	const fadeInMd = document.querySelector(".fx-fadeIn-md");

	if (fadeInMd) {
		const spacing = 85;
		const staggerEach = 0.21;
		const classfade = ".fx-fadeIn-md";

		gsap.defaults({ ease: "power3.InOut" });
		gsap.set(classfade, { y: 0, opacity: 0 });

		ScrollTrigger.batch(classfade, {
			onEnter: (batch) =>
			gsap.to(batch, { opacity: 1, y: 0, stagger: { each: staggerEach } }),
			// onLeave: (batch) =>
			// 	gsap.set(batch, { opacity: 0, y: spacing, overwrite: true }),
			onEnterBack: (batch) =>
				gsap.to(batch, {
					opacity: 1,
					y: 0,
					stagger: { each: staggerEach },
					overwrite: true,
				}),
			onLeaveBack: (batch) =>
				gsap.set(batch, { opacity: 0, y: spacing, overwrite: true }),
		});
		// ScrollTrigger.addEventListener("refreshInit", () =>
		// 	gsap.set(classfade, { y: spacing, opacity: 0 }),
		// );
	}

	const fadeInleft = document.querySelector(".fx-fadeIn-left");

	if (fadeInleft) {
		const spacing = 40;
		const staggerEach = 0.15;
		const classfade = ".fx-fadeIn-left";

		gsap.defaults({ ease: "power3.InOut" });
		gsap.set(classfade, { x: spacing, opacity: 0 });

		ScrollTrigger.batch(classfade, {
			onEnter: (batch) =>
				gsap.to(batch, {
					opacity: 1,
					x: 0,
					stagger: { each: staggerEach },
					overwrite: true,
				}),
			// onEnterBack: (batch) =>
			// 	gsap.to(batch, {
			// 		opacity: 1,
			// 		x: 0,
			// 		stagger: { each: staggerEach },
			// 		overwrite: true,
			// 	}),
			// onLeaveBack: (batch) =>
			// 	gsap.set(batch, { opacity: 0, x: -spacing, overwrite: true }),
		});
		ScrollTrigger.addEventListener("refreshInit", () =>
			gsap.set(classfade, { x: spacing, opacity: 0 }),
		);
	}
};

////////////////////////////////////////////////////
export { fx_fadeIn };
