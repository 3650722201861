// Import SCSS files as globs
// const scss = import.meta.globEager('../styles/**/*.scss')

const scssA = import.meta.globEager('../styles/style.scss')
const scssC = import.meta.globEager('../styles/components/*.scss') // 3
const scssB = import.meta.globEager('../styles/custom-style/*.scss') // 3
const scssD = import.meta.globEager('../styles/site/*.scss') // 3


// import barbaPrefetch from "@barba/prefetch";
import barba from "@barba/core";

// Site wide
import { year, scripts, videos, form, exitintents, smooth } from "./modules";

// Animations
import { 
    fx_fadeIn, 
    fx_revealImages, 
    fx_text, 
    fx_pin,
    fx_graphic
} from "./animations";

// Page Transitions
import { pageInHero2L2G, pageOutHero2L2G } from "./page/page@hero2L2G";
import { pageInHero3L2G, pageOutHero3L2G } from "./page/page@hero3L2G";
import { pageInHero2L4G, pageOutHero2L4G } from "./page/page@hero2L4G";
import { pageInHero3L4G, pageOutHero3L4G } from "./page/page@hero3L4G";
import { pageInHero, pageOutHero } from "./page/page@hero";


// const showAnim = gsap.from('.main-tool-bar', { 
//     yPercent: -100,
//     paused: true,
//     duration: 0.2
//   }).progress(1);
  
//   ScrollTrigger.create({
//     start: "top top",
//     end: 99999,
//     onUpdate: (self) => {
//       self.direction === -1 ? showAnim.play() : showAnim.reverse()
//     }
//   });


//   const delay = (n) => {
//     n = n || 2000;
//     // Keep official documentation wording, done -> resolve
//     // and make it more concises
//     return new Promise((resolve) => {
//         setTimeout(resolve, n);
//     });
//     };
    

// exitintents(); 


///////////////////////////////////////////////////////////////////////////
//
// Barba
//
///////////////////////////////////////////////////////////////////////////

barba.hooks.beforeEnter((data) => {
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: "smooth",
	});
});

// Tell Barba to use the prefetch plugin
// barba.use(barbaPrefetch);


barba.init({
timeout: 5000,
// logLevel: "error",
requestError: (trigger, action, url, response) => {
    // go to a custom 404 page if the user click on a link that return a 404 response status
    if (action === "click" && response.status && response.status === 404) {
        barba.go("/404/");
    }
    // prevent Barba from redirecting the user to the requested URL
    // this is equivalent to e.preventDefault() in this context
    return false;
},
transitions: [
    {
        async once(data) {
            scripts();
            fx_fadeIn();
            fx_revealImages();
            fx_text();
            fx_pin();
            fx_pin();
            year();
            smooth();
            videos(); 
            fx_graphic();
            await pageInHero(data.next.container);
        },
        async leave(data) {
            await pageOutHero(data.current.container);
            data.current.container.remove();
        },
        async enter(data) {
            scripts();
            fx_fadeIn();
            fx_revealImages();
            fx_text();
            fx_pin();
            year();
            smooth();
            videos(); 
            fx_graphic();
            await pageInHero(data.next.container);
        },
    },
    {
        name: "hero3L2G",
        to: {
            namespace: ["hero3L2G"],
        },
        async once(data) {
            scripts();
            fx_fadeIn();
            fx_revealImages();
            fx_text();
            fx_pin();
            year();
            smooth();
            videos(); 
            // exitintents(); 
           fx_graphic(); 
            await pageInHero3L2G(data.next.container);
        },
        async leave(data) {
            await pageOutHero3L2G(data.current.container);
            data.current.container.remove();
        },
        async enter(data) {     
            scripts();
            fx_fadeIn();
            fx_revealImages();
            fx_text();
            fx_pin();
            year();
            smooth();
            videos(); 
            // exitintents(); 
            fx_graphic();  
            await pageInHero3L2G(data.next.container);
        },
    },
    {
        name: "hero2L4G",
        to: {
            namespace: ["hero2L4G"],
        },
        async once(data) {
            scripts();
            fx_fadeIn();
            fx_revealImages();
            fx_text();
            fx_pin();
            year();
            smooth();
            videos(); 
            // exitintents(); 
            fx_graphic();

            await pageInHero2L4G(data.next.container);
        },
        async leave(data) {
            await pageOutHero2L4G(data.current.container);
            data.current.container.remove();
        },
        async enter(data) {
            scripts();
            fx_fadeIn();
            fx_revealImages();
            fx_text();
            fx_pin();
            year();
            smooth();
            videos(); 
            fx_graphic();
            await pageInHero2L4G(data.next.container);
        },
    },
    {
        name: "hero3L4G",
        to: {
            namespace: ["hero3L4G"],
        },
        async once(data) {         
            scripts();
            fx_fadeIn();
            fx_revealImages();
            fx_text();
            fx_pin();
            year();
            videos(); 
            smooth();
            // exitintents(); 
           fx_graphic();
            await pageInHero3L4G(data.next.container);
        },
        async leave(data) {
            await pageOutHero3L4G(data.current.container);
            data.current.container.remove();
        },
        async enter(data) {
            scripts();
            fx_fadeIn();
            fx_revealImages();
            fx_text();
            fx_pin();
            year();
            smooth();
            videos(); 
            // exitintents(); 
           fx_graphic();
            await pageInHero3L4G(data.next.container);
        },
    },
    {
        name: "hero2L2G",
        to: {
            namespace: ["hero2L2G"],
        },
        async once(data) {
            scripts();
            fx_fadeIn();
            fx_revealImages();
            fx_text();
            fx_pin();
            year();
            smooth();
            videos(); 
            // exitintents(); 
            fx_graphic();
            await pageInHero2L2G(data.next.container);
        },
        async leave(data) {
            await pageOutHero2L2G(data.current.container);
            data.current.container.remove();
        },
        async enter(data) {
            scripts();
            fx_fadeIn();
            fx_revealImages();
            fx_text();
            fx_pin();
            year();
            smooth();
            videos(); 
            // exitintents(); 
            fx_graphic();
            await pageInHero2L2G(data.next.container);
        },
    },
],
});

