////////////////////////////////////////////////////
// Animation Enter
////////////////////////////////////////////////////

import { gsap } from "gsap";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

////////////////////////////////////////////////////

// Function to add and remove the page transition screen
const pageInAnimation = (container) => {
	// Call the splittingjs to transform the data-splitting texts to spans of chars
	Splitting();

	// Set call class hooks
	const logo = document.querySelector(".logo");
	const titleServices = document.querySelector(".fx-hero__services");
	const title = document.querySelector(".fx-hero__title");
	const titleSub = document.querySelector(".fx-hero__title-sub");
	const gslice1 = document.querySelector(".fx-hero__gslice1");
	const gslice2 = document.querySelector(".fx-hero__gslice2");
	const img = document.querySelector(".fx-hero__img");
	const linkItem = document.querySelectorAll(".fx-menu-hero-links");
	const linkInfo = document.querySelector(".menu-hero-info");

	
		// Dom section elements
		let DOM = {
			content: {
				title: {
					section: document.querySelector(".fx-hero__title"),
					get chars() {
						return this.section.querySelectorAll(
							".fx-hero__title .word > .char, .whitespace",
						);
					},
				},
				titleSub: {
					section: document.querySelector(".fx-hero__title-sub"),
					get words() {
						return this.section.querySelectorAll(".fx-hero__title-sub .word");
					},
				},
				timelineSettings: {
					staggerValue: 0.02,
					charsDuration: 0.72,
				},
				timelineSettings: {
					staggerValue: 0.02,
					charsDuration: 0.72,
				},
				timing: 0.72,
			},
		};

		// GSAP Defaults
		const tl = gsap.timeline({
			// repeat:-1, yoyo:1,
			defaults: {
				delay: 0,
				duration: DOM.content.timing,
				ease: "power3.inOut",
				// autoAlpha: 0,
			},
		});
		tl.to(img, { autoAlpha: 1 })
			.fromTo(logo, { autoAlpha: 0}, { autoAlpha: 1 }, "-=90%")
			.fromTo(gslice1, { x: -270 }, { x: 0, autoAlpha: 1 }, "-=90%")
			.fromTo(gslice2, { x: -110 }, { x: 0, autoAlpha: 1 }, "-=70%")
			.to(title, { autoAlpha: 1 }, "-=100%")
			.to(titleServices, { autoAlpha: 1 }, "-=20%")
			.from(
				DOM.content.title.chars,
				{
					duration: DOM.content.timelineSettings.charsDuration,
					opacity: 0,
					y: "100%",
					stagger: {
						each: DOM.content.timelineSettings.staggerValue,
					},
				},
				"-=90%",
			)
			.from(
				DOM.content.titleSub.words,
				{
					duration: 0.8,
					opacity: 0,
					y: "100%",
					stagger: {
						ease: "circ.in",
						each: 0.03,
					},
				},
				"-=80%",
			)
			.fromTo(
				linkItem,
				{ x: -10 },
				{
					duration: 0.8,
					x: 0,
					autoAlpha: 1,
					stagger: {
						ease: "circ.in",
						each: 0.08,
					},
				},
				"-=110%",
			)
			.fromTo(linkInfo, { x: -10 }, { x: 0, autoAlpha: 1 }, "-=95%");
	}

////////////////////////////////////////////////////
export { pageInAnimation };
