
////////////////////////////////////////////////////
// Page transition Out
////////////////////////////////////////////////////

import { gsap } from "gsap";
import settings  from "../setting.js";
////////////////////////////////////////////////////

// Function to add and remove the page transition screen
const pageOutHero = (container) => {

	const loadingScreen = document.querySelector(".loading-screen");
	const transitionTitle = document.querySelector('.fill-loader');
	const progressbar = document.querySelector('.progress-bar');
	const loadingLogo = document.querySelector('.loading-logo');

	return gsap
		// // .fromTo(loadingLogo,{ autoAlpha: 1, y:0}, { autoAlpha: 0, ease: "circ.out"})
		.to(loadingScreen, {
			scaleY: 1,
			scaleX: 1,
			duration: loadingScreenDuration,
			transformOrigin: "right center",
			ease: "sine.out ",
		})
	
};
////////////////////////////////////////////////////
export { pageOutHero}
