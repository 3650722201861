////////////////////////////////////////////////////
// clipImage
////////////////////////////////////////////////////

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

////////////////////////////////////////////////////

const fx_pin = (container) => {
		
	gsap.utils.toArray(".fx-pin").forEach((panel, i) => {
		ScrollTrigger.create({
				trigger: panel,
				scrub: true,
				pin: panel,
				anticipatePin: 1,
				start: "top top", 
				pinSpacing: false
		});	
	});

	// ScrollTrigger.create({
	// 	trigger: "#orange",
	// 	start: "top top",
	// 	scrub: true,
	// 	end: "bottom 150px",
	// 	pin: "#orange-content",
	// 	pinSpacing: false
	//   });
	  
	//   ScrollTrigger.create({
	// 	trigger: ".fx-pin-it",
	// 	scrub: true,
	// 	start: "top center", 
	// 	end: "+=200", // 200px past the start 
	// 	pin: ".fx-pin-it",
		
	//   });

	// console.log(st.panel); // pin element (not selector text)
	
	// ScrollTrigger.create({
	// 	trigger: ".fx-pin",
	// 	start: "top top", 
	// 	end: "bottom 150px",
	// 	pin: ".fx-pin"
	//   });
		  
};

////////////////////////////////////////////////////
export { fx_pin };
