
const fx_graphic = (container) => {
	
	const graphic1 = document.querySelector("#graphic");

	if (graphic1) {
		VANTA.HALO({
			el: "#graphic",
			mouseControls: true,
			touchControls: true,
			gyroControls: false,
			minHeight: 200.0,
			minWidth: 200.0,
			baseColor: 0x1e1e1e,
			backgroundColor: 0x0,
			amplitudeFactor: 2.20,
			xOffset: 0.2,
			yOffset: 0.20,
			size: 2.30
		});
	}
	const graphic2 = document.querySelector("#graphic2");

	if (graphic2) {
		VANTA.HALO({
			el: "#graphic2",
			mouseControls: true,
			touchControls: true,
			gyroControls: false,
			minHeight: 200.0,
			minWidth: 200.0,
			baseColor: 0x1e1e1e,
			backgroundColor: 0x0,
			amplitudeFactor: 2.20,
			xOffset: -0.03,
			yOffset: 0.10,
			size: 2.00
		});
	}
	const graphic3 = document.querySelector("#graphic3");

	if (graphic3) {
		VANTA.HALO({
			el: "#graphic3",
			mouseControls: true,
			touchControls: true,
			gyroControls: false,
			minHeight: 200.0,
			minWidth: 200.0,
			baseColor: 0xff6700,
			backgroundColor: 0xff6700,
			amplitudeFactor: 2.50,
			xOffset: 0.08,
			yOffset: 0.15,
			size: 2.10
		});
	}
	const graphic4 = document.querySelector("#graphic4");

	if (graphic4) {
		VANTA.HALO({
			el: "#graphic4",
			mouseControls: true,
			touchControls: true,
			gyroControls: false,
			minHeight: 200.0,
			minWidth: 200.0,
			baseColor: 0xff,
			backgroundColor: 0x62a8c,
			amplitudeFactor: 2.50,
			xOffset: 0.09,
			yOffset: 0.15,
			size: 2.10
		});
	}

	const graphicBranding = document.querySelector("#graphicBranding");

	if (graphicBranding) {
		VANTA.HALO({
			el: "#graphicBranding",
			mouseControls: true,
			touchControls: true,
			gyroControls: false,
			minHeight: 200.0,
			minWidth: 200.0,
			backgroundColor: 0x20202,
			amplitudeFactor: 0.70,
			xOffset: -0.15,
			yOffset: 0.21,
			size: 2.50
		});
	}



	const graphic6 = document.querySelector("#graphicA");

	if (graphic6) {
		VANTA.HALO({
			el: "#graphicA",
			mouseControls: true,
			touchControls: true,
			gyroControls: false,
			minHeight: 200.00,
			minWidth: 200.00,
			baseColor: 0x0,
			backgroundColor: 0xff7100,
			amplitudeFactor: 1.60,
			xOffset: 0.18,
			yOffset: -0.32,
			size: 2.40
		});
	}



};

////////////////////////////////////////////////////
export { fx_graphic };
