

const year = (container) => {
  if(document.getElementById("year")){
    document.getElementById("year").innerHTML = new Date().getFullYear();
  }
  
}
export default year;

