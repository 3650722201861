////////////////////////////////////////////////////
// FadeIn
////////////////////////////////////////////////////

// import { set } from "date-fns";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

////////////////////////////////////////////////////


const fx_parallax = (container) => {


////////////////////////////////////////////////////


// const parallax = document.querySelectorAll("[data-scroll]");

// gsap.to("[data-speed]", {
// y: (i, el) => (0.01 - parseFloat(el.getAttribute("data-speed"))) * ScrollTrigger.maxScroll(window) ,
// 	ease: "none",
// 	scrollTrigger: {
// 	  start: 0,
// 	  end: "max",
// 	  invalidateOnRefresh: true,
// 	  scrub: 0
// 	},
	
// });
// console.log(el.dataset.speed)

//   gsap.to("[data-scroll-horizontal]", {
// 	x: (i, el) => (0.01 - parseFloat(el.getAttribute("data-speed"))) * ScrollTrigger.maxScroll(window) ,
// 		ease: "none",
// 		scrollTrigger: {
// 		  start: 0,
// 		  end: "max",
// 		  invalidateOnRefresh: true,
// 		  scrub: 0
// 		}
// 	  });



// // make the boxes random colors
// gsap.set(".box", {backgroundColor: "rgb(random(0,190), random(0,190), random(0,190)"});

// // apply parallax effect to any element with a data-speed attribute
// gsap.to("[data-speeds]", {
//   x: (i, el) => (1 - parseFloat(el.getAttribute("data-speeds"))) * ScrollTrigger.maxScroll(window) ,
//   ease: "none",
//   scrollTrigger: {
//     start: 0,
//     end: "max",
//     invalidateOnRefresh: true,
//     scrub: 0
//   }
// });


//   const parallax = gsap.utils.toArray("[data-speed]");
//   parallax.forEach((el) => {
// 	console.log(el.dataset.speed);

// 	});
// 

// parallax.forEach((el) => {
// 	data-scroll
// 	const test = (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.scroll.speed;
// 	gsap.fromTo(parallax, { 
// 		y: -1 * ((i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.scroll.speed)
// 	},{
// 		y: test,	
// 		ease: 'none',
// 		scrollTrigger: {
// 			trigger: parallax,
// 			start: "top 0%", 
// 			end: "bottom 0%",
// 			scrub: true,
// 			markers: true
// 		}
// 	});
// 	console.log(el.dataset.speed);
// });






};

////////////////////////////////////////////////////
export { fx_parallax  }
