////////////////////////////////////////////////////
// Page transition In
////////////////////////////////////////////////////

import { gsap } from "gsap";
import { pageInAnimation } from './pageInAnimation'

////////////////////////////////////////////////////

// Function to add and remove the page transition screen
const pageInHero= (container) => {
	const loadingScreen = document.querySelector(".loading-screen");
	const loader = document.querySelector(".fill-loader");
	const progressbar = document.querySelector(".progress-bar");
	const loadingDuration = 0.5;

	return gsap
		.timeline({ delay: loadingDuration }) // More readable to put it here
		.fromTo(loader, { autoAlpha: 1, y: 0 }, { autoAlpha: 0, ease: "sine.out" })
		.add("start") // Use a label to sync screen and content animation
		.to(
			loadingScreen,
			{
				duration: loadingDuration,
				scaleX: 0,
				scale: 1,
				transformOrigin: "right top",
				ease: "sine.out",
			},
			"start",
		)
		.call(pageInAnimation, [container], "start");
	
	
}
/////////////////////////////////////////r///////////
export { pageInHero }