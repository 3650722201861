////////////////////////////////////////////////////
// FadeIn
////////////////////////////////////////////////////
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

////////////////////////////////////////////////////

// Function to add and remove the page transition screen
const fx_text = (container) => {
	
	// Call the splittingjs to transform the data-splitting texts to spans of chars
	Splitting();

	// Dom section elements
	let DOM = {
		content: {
			letters: {
				section: document.querySelector(".fx-type"),
				get chars() {
					return this.section.querySelectorAll(
						".fx-type__letter .word > .char, .whitespace",
					);
				},
			},
			lettersLeft: {
				section: document.querySelector(".fx-type"),
				get chars() {
					return this.section.querySelectorAll(
						".fx-type__letter-left .word > .char, .whitespace",
					);
				},
			},
			words: {
				section: document.querySelector(".fx-type"),
				get chars() {
					return this.section.querySelectorAll(
						".fx-type__word .word, .whitespace",
					);
				},
			},
			timelineSettings: {
				staggerValue: 0.02,
				charsDuration: 0.35,
				wordsStaggerValue: 0.048,
				wordsDuration: 0.48,
			},
		},
	};

	//////////////////////////////////////////////////////////////////////////////////
	// Words
	//////////////////////////////////////////////////////////////////////////////////
	if (DOM.content.words.section !== true ) {
		gsap.set(DOM.content.words.chars, { y: "100%", opacity: 0 });
		ScrollTrigger.batch(DOM.content.words.chars, {
			onEnter: (batch) =>
				gsap.to(batch, {
					duration: DOM.content.timelineSettings.charsDuration,
					opacity: 1,
					y: 0,
					stagger: { each: DOM.content.timelineSettings.staggerValue },
					overwrite: true,
				}),
			onEnterBack: (batch) =>
				gsap.to(batch, {
					duration: DOM.content.timelineSettings.charsDuration,
					opacity: 1,
					y: 0,
					stagger: { each: DOM.content.timelineSettings.staggerValue },
					overwrite: true,
				}),
			onLeaveBack: (batch) =>
				gsap.set(batch, { opacity: 0, y: 100, overwrite: true }),
		});
	}

	////////////////////////////////////////////////////////////////////////////////
	// letters
	////////////////////////////////////////////////////////////////////////////////
	if (DOM.content.letters.section !== true) {
		gsap.set(DOM.content.letters.chars, { y: "100%", opacity: 0, });
		ScrollTrigger.batch(DOM.content.letters.chars, {
			onEnter: (batch) =>
				gsap.to(batch, {
					duration: DOM.content.timelineSettings.charsDuration,
					opacity: 1,
					y: 0,
					stagger: { each: DOM.content.timelineSettings.staggerValue },
					overwrite: true,
				}),
			onEnterBack: (batch) =>
				gsap.to(batch, {
					duration: DOM.content.timelineSettings.charsDuration,
					opacity: 1,
					y: 0,
					stagger: { each: DOM.content.timelineSettings.staggerValue },
					overwrite: true,
				}),
			onLeaveBack: (batch) =>
				gsap.set(batch, { opacity: 0, y: 100, overwrite: true }),
		});
	}

	//////////////////////////////////////////////////////////////////////////////////
	// Letters Left
	//////////////////////////////////////////////////////////////////////////////////
	if (DOM.content.lettersLeft.section !== true) {
		gsap.set(DOM.content.lettersLeft.chars, { x: "-10%" });
		ScrollTrigger.batch(DOM.content.lettersLeft.chars, {
			onEnter: (batch) =>
				gsap.to(batch, {
					duration: DOM.content.timelineSettings.charsDuration,
					opacity: 1,
					x: 0,
					stagger: { each: DOM.content.timelineSettings.staggerValue },
					overwrite: true,
				}),
			onEnterBack: (batch) =>
				gsap.to(batch, {
					duration: DOM.content.timelineSettings.charsDuration,
					opacity: 1,
					x: 0,
					stagger: { each: DOM.content.timelineSettings.staggerValue },
					overwrite: true,
				}),
			onLeaveBack: (batch) =>
				gsap.set(batch, { opacity: 0, x: "-10%", overwrite: true }),
		});
	}
};
////////////////////////////////////////////////////
export { fx_text };
