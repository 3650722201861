////////////////////////////////////////////////////
// clipImage
////////////////////////////////////////////////////

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

////////////////////////////////////////////////////

const fx_revealImages = (container) => {
	let timing = 1.4;
	const startPoint = "center -=200%";
	const revealImageLeft = gsap.utils.toArray(".fx-reveal--left");
	const revealImageRight = gsap.utils.toArray(".fx-reveal--right");
	const revealImageTop = gsap.utils.toArray(".fx-reveal--top");
	const revealImage = gsap.utils.toArray(".fx-reveal");

	ScrollTrigger.matchMedia({
		// large
		"(min-width: 960px)": function () {
			if (document.querySelector(".fx-reveal--left")) {
				let revealContainers = gsap.utils.toArray(".fx-reveal--left");

				revealContainers.forEach((container) => {
					
					let image = container.querySelector("img");

					let tl = gsap.timeline({
						scrollTrigger: {
							trigger: container,
							toggleActions: "restart none none reset",
						},
					});

					tl.set(container, { autoAlpha: 1 });
					tl.from(container, {
						xPercent: -100,
						ease: "circ.out",
						duration: timing,
					});
					tl.from(image, {
						xPercent: 100,
						scale: 1.3,
						delay: -timing,
						ease: "circ.out",
						duration: timing,
					});
				});
			}

			if (document.querySelector(".fx-reveal--right")) {
				let revealContainers = gsap.utils.toArray(".fx-reveal--right");

				revealContainers.forEach((container) => {
					let image = container.querySelector("img");

					let tl = gsap.timeline({
						scrollTrigger: {
							trigger: container,
							toggleActions: "restart none none reset",
						},
					});

					tl.set(container, { autoAlpha: 1 });
					tl.from(container, {
						xPercent: 100,
						ease: "circ.out",
						duration: timing,
					});
					tl.from(image, {
						xPercent: -100,
						scale: 1.3,
						delay: -timing,
						ease: "circ.out",
						duration: timing,
					});
				});
			}

			if (document.querySelector(".fx-reveal--top")) {
				let revealContainers = gsap.utils.toArray(".fx-reveal--top");

				revealContainers.forEach((container) => {
					let image = container.querySelector("img");

					let tl = gsap.timeline({
						scrollTrigger: {
							trigger: container,
							start: "top +=200%",
							end: () => `+=${container.offsetHeight}`, // will be updated
							toggleActions: "restart none none reset",
						},
					});

					tl.set(container, { autoAlpha: 1 });
					tl.from(container, {
						yPercent: 100,
						ease: "circ.out",
						duration: timing,
					});
					tl.from(image, {
						yPercent: -100,
						scale: 1.3,
						delay: -timing,
						ease: "circ.out",
						duration: timing,
					});
				});
			}
		},

		// medium
		"(min-width: 600px) and (max-width: 959px)": function () {},

		// small
		"(max-width: 599px)": function () {
			if (revealImage) {
				gsap.set(revealImage, { y: 30, opacity: 0 });
				ScrollTrigger.batch(revealImage, {
					ease: "power3.InOut",
					onEnter: (batch) =>
						gsap.to(batch, {
							opacity: 1,
							y: 0,
							stagger: { each: 0.15, grid: [1, 3] },
							overwrite: true,
						}),
					onLeave: (batch) =>
						gsap.set(batch, { opacity: 0, y: -30, overwrite: true }),
					onLeaveBack: (batch) =>
						gsap.set(batch, { opacity: 0, y: 30, overwrite: true }),
				});
				ScrollTrigger.addEventListener("refreshInit", () =>
					gsap.set(revealImage, { y: 30 }),
				);
			}
		},

		// all
		all: function () {},
	});
};

////////////////////////////////////////////////////
export { fx_revealImages };
